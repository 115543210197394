import { Helmet } from "react-helmet"
import React, {useState, useEffect} from 'react'
import Login  from '../components/login'
import Layout from "../components/layout"
import { Container } from "react-bootstrap";
import { Section } from "../components/section"

const LoginPage = () => {
  return (
    <Layout pageName="Login" activePage='login' containerClass="login">
      <Section>
        <Container>
          <Login />
        </Container>
      </Section>
    </Layout>
  )
}

export default LoginPage
